import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: (() => {
        try {
            return JSON.parse(localStorage.getItem('user')) || null;
        } catch (error) {
            localStorage.removeItem('user');
            return { firstName: ''};
        }
    })(),
    client_user: null,
    new_client_user: {
        firstName: "",
        lastName: "",
        email: "",
        nacionalidade: "Brasil",
        date_nascimento: null,
        sexo: null,
        check_comunicados: false,
        check_pesquisa: false,
        image: null,
        date_aprovacao: new Date().toISOString().substr(0, 10),
        categoryId: null,
        status: "ativo"
    },
    client_users: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_user: false,
    show_modal_filter_user: false,
    filters_user: [
        {
            id: 'state',
            name: 'Estado',
            type: 'select',
            isOpen: false,
            options: [
                { value: "AC", label: "Acre", checked: false },
                { value: "AL", label: "Alagoas", checked: false },
                { value: "AP", label: "Amapá", checked: false },
                { value: "AM", label: "Amazonas", checked: false },
                { value: "BA", label: "Bahia", checked: false },
                { value: "CE", label: "Ceará", checked: false },
                { value: "DF", label: "Distrito Federal", checked: false },
                { value: "ES", label: "Espírito Santo", checked: false },
                { value: "GO", label: "Goiás", checked: false },
                { value: "MA", label: "Maranhão", checked: false },
                { value: "MT", label: "Mato Grosso", checked: false },
                { value: "MS", label: "Mato Grosso do Sul", checked: false },
                { value: "MG", label: "Minas Gerais", checked: false },
                { value: "PA", label: "Pará", checked: false },
                { value: "PB", label: "Paraíba", checked: false },
                { value: "PR", label: "Paraná", checked: false },
                { value: "PE", label: "Pernambuco", checked: false },
                { value: "PI", label: "Piauí", checked: false },
                { value: "RJ", label: "Rio de Janeiro", checked: false },
                { value: "RN", label: "Rio Grande do Norte", checked: false },
                { value: "RS", label: "Rio Grande do Sul", checked: false },
                { value: "RO", label: "Rondônia", checked: false },
                { value: "RR", label: "Roraima", checked: false },
                { value: "SC", label: "Santa Catarina", checked: false },
                { value: "SP", label: "São Paulo", checked: false },
                { value: "SE", label: "Sergipe", checked: false },
                { value: "TO", label: "Tocantins", checked: false }
            ],
        },
        {
            id: 'status_financial',
            name: 'Situação Financiero',
            type: 'select',
            isOpen: false,
            options: [
                { value: 'approved', label: 'Adimplente', checked: false },
                { value: 'pending', label: 'Inadimplente', checked: false }
            ],
        },
        {
            id: 'status_user',
            name: 'Status do Usuário',
            type: 'select',
            isOpen: false,
            options: [
                { value: 'ativo', label: 'Ativo', checked: false },
                { value: 'inativo', label: 'Inativo', checked: false },
                { value: 'pendente', label: 'Pendente', checked: false },
                { value: 'banida', label: 'Banida', checked: false },
            ],
        }
    ],
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_AUTH: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.user = payload.user
        state.token = payload.token
        if(router.currentRoute.value.path === '/auth' && !state.redirect){
            router.push({path: `/`});
        }
        state.redirect = null;
    },
    SET_USER: (state, payload) => {
        state.client_user =  payload.user
    },
    SET_USERS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.client_users = payload.itens
        }else{
            state.client_users = [...state.client_users, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.token = payload.token;
        state.user = payload.user;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('notifications');
        localStorage.removeItem('projects');

        state.token = null;
        state.isAuthenticated = false;
        state.user = null;
        state.projects = null;

        if(router.currentRoute.value.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    SET_SHOW_MODAL_NEW_USER: (state, payload) => {
        state.show_modal_new_user = payload
    },
    SET_SHOW_MODAL_FILTER_USER: (state, boolean) => {
        state.show_modal_filter_user = boolean
    },
    TOGGLE_FILTER_USER_OPTION(state, { filterId, optionValue }) {
        const filter = state.filters_user.find(f => f.id === filterId);
        if (filter) {
            const option = filter.options.find(o => o.value === optionValue);
            if (option) {
            option.checked = !option.checked;
            }
        }
    },
}

const actions = {
    async loginADM({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.loginADM(credentials);
            commit('SET_AUTH', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    async fetchUser({commit}, id){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.getOne(id);
            commit('SET_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchUsers({commit, state}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'DESC' } }){
        try{
            commit('SET_LOADING_USER', true)
            let filters = state.filters_user.reduce((acc, filter) => {
                acc[filter.id] = filter.options.filter(option => option.checked).map(option => option.value);
                return acc;
            }, {});
            const response = await UserService.get(keyword, pageNumber, filters, order);
            commit('SET_USERS', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async exportAllUsers({ state }, { keyword = '', order = { name: 'ID', column: 'ID', sort: 'DESC' } }) {
        try {
            let filters = state.filters_user.reduce((acc, filter) => {
                acc[filter.id] = filter.options.filter(option => option.checked).map(option => option.value);
                return acc;
            }, {});

            const response = await UserService.export(keyword, filters, order);

            const date = new Date().toISOString().slice(0, 10);
            const fileName = `Relatorio_Usuarios_${date}.csv`;
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao exportar usuários:', error);
        }
    },
    async createUser({commit, state}, data){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.createUser(data);
            commit('SET_SHOW_MODAL_NEW_CATEGORY', false)
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            state.new_client_user = { firstName: "", lastName: "", email: "", nacionalidade: "Brasil", date_nascimento: null, sexo: null, check_comunicados: false, check_pesquisa: false, image: null, date_aprovacao: new Date().toISOString().substr(0, 10), categoryId: null, status: "ativo"};
            router.push({path: `/form_user/${response.data.user.id}`});
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeUser({commit}, data){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.updateUser(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    toggleFilterUserOption({ commit, dispatch }, payload) {
        commit('TOGGLE_FILTER_USER_OPTION', payload);
        dispatch('fetchUsers', {keyword: "", pageNumber: 1})
    },
}

const getters = {
    getUser: state => state.user,
    getNewClientUser: state => state.new_client_user,
    getClientUser: state => state.client_user,
    getClientUsers: state => state.client_users,
    getLoadingUser: state => state.loadingUser,
    getShowModalNewUser: state => state.show_modal_new_user,
    getShowModalSearchUser: state => state.show_modal_filter_user,
    getFiltersUser: state => state.filters_user
}

export default {
    state,
    getters,
    mutations,
    actions
};