import PaymentService from '../../services/PaymentService'
import router from '@/router'

const state = () => ({
    loadingPayments: false,
    payment: null,
    new_payment: {
        payment_method_id: "",
        transaction_amount: null,
        installments: 1,
        description: "",
        UserId: null,
        SubscriptionId: null,
        PlanId: null,
        CuponId: null,
        DiscountId: null,
        transaction_id: null,
        external_reference: null,
        customer_id: null,
        identificationNumber: null,
        cardholderEmail: null,
        status: "approved"
    },
    payments: [],
    paymentsSubscriptions: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_payment: false,
    show_modal_filter_payment: false,
    filters_payment: [
        {
            id: 'status',
            name: 'Status',
            type: 'select',
            isOpen: false,
            options: [
                { value: 'approved', label: 'Aprovado', checked: false },
                { value: 'pending', label: 'Pendente', checked: false },
                { value: 'rejected', label: 'Rejeitado', checked: false },
                { value: 'expired', label: 'Expirado', checked: false },
                { value: 'refunded', label: 'Reembolsado', checked: false },
                { value: 'charged_back', label: 'Estornado', checked: false },
                { value: 'cancelled', label: 'Cancelado', checked: false }
            ],
        },
    ],
})
  
const mutations = {
    SET_PAYMENT: (state, payload) => {
        state.payment =  payload
    },
    SET_PAYMENTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.payments = payload.payments
        }else{
            state.payments = [...state.payments, ...payload.payments]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_PAYMENTS_SUBSCRIPTIONS: (state, payload) => {
        state.paymentsSubscriptions =  payload
    },
    SET_LOADING_PAYMENTS: (state, payload) => {
        state.loadingPayments = payload
    },
    SET_SHOW_MODAL_NEW_PAYMENT: (state, payload) => {
        state.show_modal_new_payment = payload
    },
    SET_SHOW_MODAL_FILTER_PAYMENT: (state, boolean) => {
        state.show_modal_filter_payment = boolean
    },
    TOGGLE_FILTER_PAYMENT_OPTION(state, { filterId, optionValue }) {
        const filter = state.filters_payment.find(f => f.id === filterId);
        if (filter) {
            const option = filter.options.find(o => o.value === optionValue);
            if (option) {
            option.checked = !option.checked;
            }
        }
    },
}

const actions = {
    async fetchPayments({commit, state}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'DESC' } }){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            let filters = state.filters_payment.reduce((acc, filter) => {
                acc[filter.id] = filter.options.filter(option => option.checked).map(option => option.value);
                return acc;
            }, {});
            const response = await PaymentService.get(keyword, pageNumber, filters, order);
            commit('SET_PAYMENTS', response.data)
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async exportAllPayments({ state }, { keyword = '', order = { name: 'ID', column: 'ID', sort: 'DESC' } }) {
        try {
            let filters = state.filters_payment.reduce((acc, filter) => {
                acc[filter.id] = filter.options.filter(option => option.checked).map(option => option.value);
                return acc;
            }, {});

            const response = await PaymentService.export(keyword, filters, order);

            const date = new Date().toISOString().slice(0, 10);
            const fileName = `Relatorio_Pagamentos_${date}.csv`;
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao exportar usuários:', error);
        }
    },
    async fetchPayment({commit}, id){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.getOne(id);
            commit('SET_PAYMENT', response.data)
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    
    async fetchPaymentByTransaction_id({commit}, id){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.getOneByTransaction_id(id);
            commit('SET_PAYMENT', response.data)
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchFaturasSubscription_MP({commit}, id){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.getFaturasSubMP(id);
            commit('SET_PAYMENTS_SUBSCRIPTIONS', response.data.items)
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPayment({commit, state}, data){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_NEW_PAYMENT', false)
            state.new_payment = { payment_method_id: "", transaction_amount: null, installments: 1, description: "", UserId: null, SubscriptionId: null, PlanId: null, CuponId: null, DiscountId: null, transaction_id: null, external_reference: null, customer_id: null, identificationNumber: null, cardholderEmail: null, status: "ativo" };
            router.push({path: `/form_payment/${response.data.item.id}`});
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadePayment({commit}, data){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    toggleFilterPaymentOption({ commit, dispatch }, payload) {
        commit('TOGGLE_FILTER_PAYMENT_OPTION', payload);
        dispatch('fetchPayments', {keyword: "", pageNumber: 1})
    },
}

const getters = {
    getPayment: state => state.payment,
    getNewPayment: state => state.new_payment,
    getPayments: state => state.payments,
    getPaymentsSubscriptions: state => state.paymentsSubscriptions,
    getLoadingPayment: state => state.loadingPayments,
    getShowModalNewPayment: state => state.show_modal_new_payment,
    getShowModalSearchPayment: state => state.show_modal_filter_payment,
    getFiltersPayment: state => state.filters_payment
}

export default {
    state,
    getters,
    mutations,
    actions
};