import Api from './Api'

export default {
    get(keyword, pageNumber, filters, order) {
        const params = new URLSearchParams();

        params.append('keyword', keyword);
        params.append('pageNumber', pageNumber);
        params.append('orderColumn', order.column);
        params.append('orderSort', order.sort);
        
        // Serializando os filtros
        Object.keys(filters).forEach(key => {
            filters[key].forEach(value => {
                params.append(`${key}[]`, value);
            });
        });

        return Api().get(`users`, { params });
    },
    export(keyword, filters, order) {
        const params = new URLSearchParams();
        params.append('keyword', keyword);
        params.append('export', true);  // Parâmetro para exportar todos os dados
        params.append('orderColumn', order.column);
        params.append('orderSort', order.sort);

        // Serializando os filtros
        Object.keys(filters).forEach(key => {
            filters[key].forEach(value => {
                params.append(`${key}[]`, value);
            });
        });

        return Api().get('users', {
            params,
            responseType: 'blob' // Define o tipo de resposta como blob para tratar arquivos
        });
    },
    getOne(id) {
        return Api().get(`users/${id}`);
    },
    createUser(data){
        return Api().post(`users`, data);
    },
    updateUser(credentials){
        return Api().put(`users/${credentials.id}`, credentials);
    },
}